var wipwrp = window.wipwrp || {};

wipwrp.fieraMilanoPortalModal = (function($,mzr,doc,win) {
    var self = {};
    var modal = document.querySelector('#fiera-milano-portal-modal');

    function modalShow() {
        modal.style.display = '';
        vex.dialog.confirm({
            unsafeMessage: modal,
            showCloseButton: true,
            overlayClosesOnClick:false,
            buttons: [ ],
            callback: function () {
                // NOOP
            }
        });
    }

    self.init = function () {
        if (!modal) return;
        $('.fiera-milano-portal-modal').on('click', function(){
            modalShow();
        });
    }

    return self;
})(jQuery,Modernizr, document, window);